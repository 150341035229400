<div class="front">
    <div class="frontPictureContainer">
        <div class="frontPicture" [@fadeIn]="state" (@fadeIn.done)="onDone($event)" [style.backgroundImage]="currentImage">
        </div>
        <div class="navbarFront container">
            <div class="navFrontBar">
                <div class="navAboutUs navbarFont  animations2" (click)="goToAboutUs()">
                    O nas
                </div>
                <div class="navOffers navbarFont  animations1" (click)="goToOfferingThins()">
                        Storitve
                </div>
                <div class="navContacts navbarFont  animations3" (click)="goToContacts()">
                    Kontakt
                </div>
                <div class="navSendEmail navbarFont  animations4" (click)="goToContactUs()">
                    Pošlji sporočilo
                </div>
            </div>
        </div>
    </div>

</div>