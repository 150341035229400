export const offers = {
    "offers": [
        {
            "offerName": "Izdelava okroglih betonskih jam",
            "url": "../../assets/jama.jpg",
            "images": [
                {
                    src: "../../assets/jama.jpg",
                },
                {
                    src: "../../assets/20190620_090807.jpg",
                },
                {
                    src: "../../assets/20190620_130646.jpg",
                },
                {
                    src: "../../assets/20191129_111023.jpg",
                },
                {
                    src: "../../assets/20200324_161017.jpg",
                },
                {
                    src: "../../assets/20200328_134000.jpg",
                },
                {
                    src: "../../assets/20200515_112422.jpg",
                },
            ]
        },
        {
            "offerName": "Izdelava betonskih oglatih jam",
            "url": "../../assets/20190330_131053.jpg",
            "images": [
                {
                    src: "../../assets/20190330_131053.jpg",
                },
                {
                    src : '../../assets/123600578_3894609077219002_4911504399469128176_n.jpg'
                },
                {
                    src : '../../assets/123139389_370569677590232_2305993824964037673_n.jpg'
                },
                {
                    src : '../../assets/123404661_419147525744551_2386797214409082406_n.jpg'
                },
                {
                    src: "../../assets/20190413_173416.jpg",
                },
            ]
        },
        {
            "offerName": "Izdelava betonski silosov",
            "url": "../../assets/123434492_353857659399966_7047405109541613408_n.jpg",
            "images": [
                {
                    src: "../../assets/123434492_353857659399966_7047405109541613408_n.jpg",
                },
                {
                    src: './../assets/123223372_755380395047264_1485485718715322629_n.jpg'
                },
                {
                    src : '../../assets/123378148_3737240012961584_2464606413020366767_n.jpg'
                }
            ]
        },
        {
            "offerName": "Izdelava škarp",
            "url": "../../assets/skarpa.jpg",
            "images": [
                {
                    src: "../../assets/skarpa.jpg",
                },
                {
                    src: "../../assets/20191207_084500.jpg",
                },
                {
                    src: "../../assets/20191210_092335.jpg",
                },
                {
                    src: "../../assets/20200314_093523.jpg",
                },
                {
                    src: "../../assets/okolica.jpg",
                },
                {
                    src: "../../assets/rusenje.jpg",
                },
            ]
        },
        {
            "offerName": "Strojni izkopi",
            "url": "../../assets/20200405_092331.jpg",
            "images": [
                {
                    src: "../../assets/20200405_092331.jpg",
                },
                {
                    src: './../assets/123229855_1115077882250956_2213347111915696590_n.jpg'
                },
                {
                    src: "../../assets/strojniizkopi.jpg",
                },
                {
                    src: "../../assets/20191201_142852.jpg",
                },
                {
                    src: "../../assets/20200211_161746.jpg",
                },
            ]
        },
        {
            "offerName": "Podboji asfaltnih cest",
            "url": "../../assets/podboj.jpg",
            "images": [
                {
                    src: "../../assets/podboj.jpg",
                },
                {
                    src: "../../assets/20200415_154949.jpg",
                },
                {
                    src: "../../assets/prebojjj.jpg",
                },
                {
                    src: "../../assets/prebojj.jpg",
                },
            ]
        },
        {
            "offerName": "Izdelava kanalizacij",
            "url": "../../assets/kanalizacija.jpg",
            "images": [
                {
                    src: "../../assets/kanalizacija.jpg",
                },
            ]
        },
        {
            "offerName": "Prevozi",
            "url": "../../assets/prevozi.jpg",
            "images": [
                {
                    src: "../../assets/prevozi.jpg",
                },
                {
                    src: './../assets/123229855_1115077882250956_2213347111915696590_n.jpg'
                }
            ]
        },
        {
            "offerName": "Rušenje objektov",
            "url": "../../assets/rusenje.jpg",
            "images": [
                {
                    src: "../../assets/rusenje.jpg",
                },
            ]
        },
        {
            "offerName": "Urejanje okolice",
            "url": "../../assets/123363172_366918837882367_2194277515171493793_n.jpg",
            "images": [
                {
                    src : '../../assets/123363172_366918837882367_2194277515171493793_n.jpg'
                }
            ]
        },
        {
            "offerName": "Izdelava gozdnih vlak",
            "url": '../../assets/gozdnavlaka.jpg',
            "images": [
                { src: '../../assets/gozdnavlaka.jpg'},
                { src: "../../assets/20191201_142852.jpg" }
            ]
        },
        {
            "offerName": "Rezervoarji s pitno vodo",
            "url": "../../assets/rezervv.jpg",
            "images": [
                {
                    src: "../../assets/rezervv.jpg",
                },
            ]
        },
        {
            "offerName": "Vgradnja čistilnih naprav",
            "url": "../../assets/cistilna.jpg",
            "images": [
                {
                    src: "../../assets/cistilna.jpg",
                },
            ]
        },
        {
            "offerName": "Nakladanje in razkladanje s hiabom",
            "url": "../../assets/hiap.jpg",
            "images": [
                {
                    src: "../../assets/hiap.jpg",
                },
                {
                    src: "../../assets/prevozi.jpg",
                },
                {
                    src: '../../assets/124107958_991620584648577_6741250377215128951_n.jpg'
                }
            ]
        },
        {
            "offerName": "Strojna dela v objektu",
            "url": "../../assets/123257427_3544476445691577_496567012480985685_n.jpg",
            "images": [
                {
                    src: "../../assets/123257427_3544476445691577_496567012480985685_n.jpg",
                },
                
                {
                    src: "../../assets/123562514_2711965179063707_707548432941955980_n.jpg",
                },
                {
                    src: "../../assets/123322198_397437034625026_6888727262384346523_n.jpg",
                },
                {
                    src: '../../assets/124296425_3720518191292284_7955082003817456552_n.jpg'
                }
            ]
        },
    ]
};