<section class="section section-xl bg-gray-700 mainContainer">
  <div class="title container centerAlign">
    <div class="titleText centerAlign ">
      STORITVE
    </div>
  </div>
    <div class="container">
      <div class="row row-15 row-gutters-14">
        <div class="col-md-6 col-lg-4 wow fadeInUp containerpadding cardPhone " *ngFor="let offer of offers">
            <card [offer]="offer"></card>
        </div>
      </div>
    </div>
</section>
