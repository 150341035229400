<div class="contacts">
  <div class="aboutContainer">
    <!-- Services-->
    <section class="page-section " id="services">
       <div class="container conctactcontainer">
         <div class="stepintouch">
          <h2 class="text-center mt-0">Stopite v kontakt z nami!</h2>
          <div class="divider my-4">
            <div class="dividercenter"></div>
          </div>
         </div>
           <p class="centerAlign">Želite, da vam uredimo zunanjost hiše ali posesti. Želite novo gnojno jamo, morda gozdno vlako ali pa kaj drugega?</p>
           <div class="row">
               <div class="col-lg-3 col-md-6 text-center">
                   <div class="mt-5">
                       <fa-icon [icon]="phone"  class="fas fa-4x fa-globe text-primary mb-4"></fa-icon>
                       <p class="text-muted mb-0">041-762-789</p>
                   </div>
               </div>
               <div class="col-lg-3 col-md-6 text-center">
                   <div class="mt-5">
                       <fa-icon [icon]="envelope" class="fas fa-4x fa-heart text-primary mb-4"></fa-icon>
                       <a href="mailto:ivanpesjak@gmail.com">
                        <p class="text-muted mb-0">ivanpesjak@gmail.com</p>
                      </a>
                   </div>
               </div>
              <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <fa-icon [icon]="address"  class="fas fa-4x fa-globe text-primary mb-4"></fa-icon>
                    <p class="text-muted mb-0">Brezen 66</p>
                    <p class="text-muted mb-0">3205 Vitanje</p>
                </div>
            </div>
           </div>
       </div>
   </section>
</div>
  <div *ngIf="successfullySent" class="container backgroundd centerAlignSend ">
    <div style="text-align:center">
      <h2>Sporočilo uspešno poslano!</h2>
      <h3 class="curser" (click)="goToTop()">Pojdi na vrh strani</h3>
    </div>
  </div>
  <div *ngIf="!successfullySent" class="container backgroundd">
    <div style="text-align:center">
      <h2>Pišite nam</h2>
      <p>Pošljite nam svoje povpraševanje. Odgovorili bomo v najkrajšem možnem času.</p>
    </div>
    <div class="row rowContainer">
      <div class="column">
        <div>
          <label for="fname" >Ime</label>
          <input type="text" id="fname" name="firstname" (keyup)="onNameChange($event)">
          <label for="lname" >Priimek</label>
          <input type="text" id="lname" name="lastname" (keyup)="onSurnameChange($event)">
          <label for="lname" >E-mail</label>
          <input type="text" id="lname" name="email" (keyup)="onEmailChange($event)">
          <label for="lname" >Telefonska</label>
          <input type="text" id="lname" name="email" (keyup)="onTelephoneChange($event)">
          <label for="subject" >Sporočilo</label>
          <textarea id="subject" name="subject" style="height:170px" (keyup)="onMessageChange($event)"></textarea>
          <input class="sendtext" type="submit" value="Pošlji" (click)="sendEmail()">
        </div>
      </div>
    </div>
  </div>
</div>
