<div class="aboutContainer">
     <!-- Services-->
     <section class="page-section" id="services">
        <div class="container  ">
            <h2 class="text-center mt-0"  data-aos="fade-up" data-aos-once="true" >Ali želite naše storitve?</h2>
            <h3 class="centerAlign" style="margin:20px;text-align:center;">30 let izkušenj in zadovoljnih strank</h3>
            <div class="centerAlign">
                <hr class="divider my-4" />
        </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="punctuality"  class="fas fa-4x fa-laptop-code text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Točnost</h3>
                        <p class="text-muted mb-0">Dela so opravljena do določenega datuma</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="world"  class="fas fa-4x fa-globe text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Delo doma in drugod</h3>
                        <p class="text-muted mb-0">Delamo po celi Sloveniji</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="qulity" class="fas fa-4x fa-heart text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Kvalitetno delo</h3>
                        <p class="text-muted mb-0">Delo je opravljeno z obojestranskim zadovoljstvom</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="predracuni" class="fas fa-4x fa-heart text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Kvalitetni predračuni</h3>
                        <p class="text-muted mb-0">Predračuni so narejeni po predhodnem dogovoru in ogledu</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="handShake" class="fas fa-4x fa-heart text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Poslovno partnerstvo</h3>
                        <p class="text-muted mb-0">Spoštovanje strank in poslovnih partnerjev</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <fa-icon [icon]="heart" class="fas fa-4x fa-heart text-primary mb-4"></fa-icon>
                        <h3 class="h4 mb-2">Delo s srcem</h3>
                        <p class="text-muted mb-0">Projekt narejen z veseljem do dela</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>