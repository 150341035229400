<div class="box-product-figure" (mouseover)="hoverAll=true" (mouseleave)="changeHoverOff()">
    <div class="containerpicture">
        <div class="overflow">
            <img class="slika" src={{offer.url}} alt=""/>
        </div>
        
            <div class="triangleup"></div>
            <div class="trianglebottomleft"></div>
            <div class="arrowsprev" (click)="changeImage(-1)">    
                <div *ngIf="hoverAll && moreThanOnePicture" class="arrows prev"></div>
            </div>
            <div class="arrowsnext" (click)="changeImage(1)">   
                <div *ngIf="hoverAll && moreThanOnePicture" class="arrows next arrowsPhone"></div>
            </div>

    </div>
</div>
<div class="box-product-caption whitebackground midle" (mouseover)="hoverText=true" (mouseleave)="hoverText=false">
    <div class="">
        <h4 class="box-product-title midle">{{offer.offerName}}</h4>
        <div class="underline">
            <div *ngIf="hoverAll || hoverText" class="line"></div>
        </div>
    </div>
</div>